


































import { defineComponent } from "@vue/composition-api"
import RegularMeetingSolutionError from "@/models/RegularMeetingSolutionError"
import { goToRoute } from "@/router"
import RouteName from "@/constants/RouteName"
import Icon from "@/components/Icon.vue"

export default defineComponent({
  components: {
    Icon,
  },

  props: {
    cols: {
      type: [String, Number],
      default: "8",
    },
    id: {
      type: String,
    },
    hideFooter: Boolean,
  },

  setup(props, context) {
    const { title, content, footer } = context.slots
    if (!title) throw new RegularMeetingSolutionError({ message: "no-title" })
    if (!content)
      throw new RegularMeetingSolutionError({ message: "no-content" })
    if (!footer) throw new RegularMeetingSolutionError({ message: "no-footer" })

    const goToTopPage = () => {
      goToRoute({
        name: RouteName.MeetingSeries,
        params: {
          id: props.id || "",
        },
      })
    }

    return { goToTopPage }
  },
})
