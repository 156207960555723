


































































































import {
  computed,
  defineComponent,
  onMounted,
  reactive,
  watch,
} from "@vue/composition-api"
import { TypedComponentProps } from "@/vue-props-types"
import { goToRoute } from "@/router"
import RouteName from "@/constants/RouteName"
import I18nFormattedMessage from "@/components/i18n/I18nFormattedMessage.vue"
import Icon from "@/components/Icon.vue"
import IconButton from "@/components/buttons/IconButton.vue"
import TextButton from "@/components/buttons/TextButton.vue"
import TextField from "@/components/textFields/TextField.vue"
import DefaultButton from "@/components/buttons/DefaultButton.vue"
import Card from "@/components/cards/Card.vue"
import { meetingContainer } from "@/containers/MeetingsContainer"
import { teamsContextContainer } from "@/containers/TeamsContextContainer"
import { alertContainer } from "@/containers/AlertContainer"
import { i18nContainer } from "@/containers/I18nContainer"
import { MeetingStructure } from "@/models/Meeting"
import Link from "@/models/Link"
import WithBackPageLayout from "@/layouts/WithBackPageLayout.vue"
import { required, isAbsoluteUrl } from "@/utilities/Validation"
import { Props } from "./types"
import { projectsContainer } from "@/containers/ProjectsContainer"

interface State {
  isLoading: boolean
  isFormValid: boolean
  meetingStructure: MeetingStructure | null
  errorMsg: string
}

const propsOptions = {
  id: {
    type: String,
    required: true,
  },
}

export default defineComponent<TypedComponentProps<Props, typeof propsOptions>>(
  {
    props: propsOptions,
    components: {
      I18nFormattedMessage,
      Icon,
      TextField,
      DefaultButton,
      WithBackPageLayout,
      Card,
      IconButton,
      TextButton,
    },
    setup(props) {
      const {
        state: meetingState,
        updateMeetingStructureAsync,
      } = meetingContainer.useContainer()
      const { getContext } = teamsContextContainer.useContainer()
      const { getProjectAsync } = projectsContainer.useContainer()
      const { showSuccessMessage } = alertContainer.useContainer()
      const state = reactive<State>({
        isLoading: false,
        isFormValid: false,
        meetingStructure:
          meetingState.meetings.value?.find(m => m.id === props.id) ?? null,
        errorMsg: "",
      })
      const { formatMessage } = i18nContainer.useContainer()

      const onFormValidityChanged = (isValid: boolean) => {
        if (isValid) state.isFormValid = true
      }
      onMounted(async () => {
        state.meetingStructure =
          meetingState.meetings.value?.find(m => m.id === props.id) ?? null
      })

      watch(
        () => props.id,
        newValue => {
          if (!newValue)
            goToRoute({
              name: RouteName.MeetingSeries,
              params: {},
            })
        }
      )

      const parentId = computed(() => props.id)

      const save = async () => {
        if (!state.meetingStructure) return
        state.isLoading = true

        state.meetingStructure.links = state.meetingStructure?.links
        const context = await getContext()
        const project = await getProjectAsync(context.entityId)
        await updateMeetingStructureAsync(
          project.siteId,
          context.entityId,
          props.id,
          state.meetingStructure,
          true
        )
        showSuccessMessage("リンクの更新が完了しました")
        state.isLoading = false
      }

      const urlNameRule = [required]

      const validUrl = (url: string) => {
        return isAbsoluteUrl(url)
      }

      const urlInputRules = [
        (value: string) => {
          if (value === "")
            return formatMessage({
              id: "InputURL.required",
              defaultMessage: "必須入力です",
            })

          if (validUrl(value)) return true
          return formatMessage({
            id: "InputURL.notValidURL",
            defaultMessage: "有効なURLを入力してください",
          })
        },
      ]

      const deleteRow = (index: number) => {
        const links = state.meetingStructure?.links
        if (!links) return
        links.splice(index, 1)
      }

      const addRow = () => {
        if (!state.meetingStructure) return

        if (!state.meetingStructure.links) {
          state.meetingStructure.links = []
        }
        const linksLenth = state.meetingStructure.links.length
        state.meetingStructure?.links.push(
          new Link({
            order: linksLenth + 1,
            displayName: "",
            url: "",
          })
        )
      }

      return {
        state,
        parentId,
        meetingState,
        onFormValidityChanged,
        i18nFormattedMessage: formatMessage,
        save,
        deleteRow,
        addRow,
        urlInputRules,
        urlNameRule,
      }
    },
  }
)
