class Link {
  order: number | null = null
  displayName = ""
  url = ""

  constructor(input?: Link) {
    if (!input) return
    this.order = input.order
    this.displayName = input.displayName
    this.url = input.url
  }
}

export default Link
